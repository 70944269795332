@import "../../styles/resources.scss";

.bm-menu-wrap {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2), 0 0 0.2rem rgba(0, 0, 0, 0.2);
  padding: 26px $base-spacing $base-spacing * 2;
  position: relative !important;
  transform: none !important;
  overflow: auto;
  width: $sidebar-width !important;
  @include grid-media($grid--desktop-lg-up) {
    width: $sidebar-lg-width !important;
  }
  user-select: none;
  background: $josef-white;
  color: $josef-black;

  .react-switch-handle {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.bm-menu {
  height: auto !important;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  // below props to avoid JoButton focus clipping
  box-sizing: border-box;
  overflow: visible !important;
  padding: 0 3px;
  width: calc(100% + 5px);
  margin-left: -3px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item-list {
  flex: 1 0 auto;
}

.bm-cross-button {
  width: 16px;
  height: 16px;
  right: 1rem;
  top: 1.125rem;
  display: block;
  fill: $josef-purple;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: center center;
  background-color: transparent;

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  button {
    &:focus {
      outline: 0 none;
    }
  }
}

// unifies animation of recipients tab and sidebar
.sidebar {
  &__wrapper {
    transform: translate3d(100%, 0px, 0px);
    transition: $sidebar-duration $sidebar-ease transform !important;
    height: calc(100% - 105px) !important;
    position: fixed;
    right: 0;
    top: 7.4em; //105px
    width: $sidebar-width !important;
    @include grid-media($grid--desktop-lg-up) {
      width: $sidebar-lg-width !important;
    }

    &__opened {
      transform: translate3d(0%, 0px, 0px);
      transition: $sidebar-duration $sidebar-ease transform;
      height: calc(100% - 105px) !important;
      position: fixed;
      right: 0;
      top: 7.4em;
    }
  }

  &__tab + div,
  &__tab__hidden + div {
    height: 100%;
  }

  &__divider {
    width: 110%;
    margin: $base-spacing 0 $large-spacing $base-spacing * -1;
  }

  p {
    color: $josef-neutral-400;
  }
}
