@import "../../styles/resources.scss";

.tab-indicator {
  position: relative;
  top: -($base-spacing * 1.35);
  border: none;
  height: 2px;
  background: $josef-wolf;
  z-index: -1;
  margin-bottom: 32px;
}

.tab-content {
  margin-top: -24px;
}

.tab-buttons {
  display: inline-flex;

  .ds-tab {
    text-align: center;
    font-weight: bold;
    background: transparent;
    padding-bottom: 16px;
    cursor: pointer;
    color: $josef-grey;
    letter-spacing: -0.01em; // from figma design
    margin-right: 24px;
    position: relative;

    &.inactive {
      &:hover {
        color: $josef-neutral-400;
        padding-bottom: 12px;
        margin-bottom: 4px;

        &:not(:first-child) {
          &::before {
            content: "";
            position: absolute;
            width: calc(100% + 24px);
            height: 150%;
            background: $josef-neutral-100;
            z-index: -1;
            left: -12px;
            top: -50%;
          }
        }

        // first child needs to have additinal width to account for the parent margin
        &:first-child {
          &::before {
            content: "";
            position: absolute;
            width: calc(100% + 27px);
            height: 150%;
            background: $josef-neutral-100;
            z-index: -1;
            left: -15px;
            top: -50%;
          }
        }
      }
    }

    &.active {
      border-bottom: 6px solid $josef-purple;
      color: $josef-purple;
      padding-bottom: 10px;
    }
  }
}
