@import "../../styles/resources.scss";

.rule-group__name-container {
  display: flex;
  align-items: center;
  margin-bottom: $base-spacing * 2;

  .jo-link {
    margin-bottom: 0;
    width: 0.75em;
    font-size: 0.875em;
  }
}

.rule-group__name {
  &:focus {
    outline: none;
    text-overflow: inherit;
  }
}

.rule-group--child {
  width: 100%;
}
