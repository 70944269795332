@import "../../styles/resources.scss";

.block-menu {
  background: $josef-purple-pale;
  border-radius: $base-border-radius;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: -1.25rem;
  margin-top: 3.875rem;
  box-shadow: $shadow-base;
  will-change: transform;

  &__wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}
