@import "../../styles/resources.scss";

.react-select {
  &__dropdown-icon {
    display: flex;

    &.is-grey {
      color: $josef-grey;
    }
  }
}
