@import "../../styles/resources.scss";

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 500;
}

.ReactModal__Content {
  z-index: 1000;
}

.attachment-label {
  margin-top: 24px;
}

.attachment-hint {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: black;
  margin-left: 3px;

  & span {
    color: white;
    width: 17px;
    height: 10px;
  }
}

.actions-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}
