@import "../../styles/resources.scss";

.cardlist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.857em; //12px
  margin-bottom: 1em;
}
.card {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
  height: 3.786em; //53px
  width: 100%;
  border: 1px solid $josef-wolf;
  border-radius: 0.571em; //8px
  padding-left: 1.143em; //16px
  cursor: pointer;

  &.selected {
    border: 1px solid $josef-purple;
  }
}

// Draw the radio button using 3 layers: inner circle(purple), border(white), and box-shadow(purple)
.radio {
  width: 0.714em; //10px
  height: 0.714em;
  border: 1.75px solid $josef-white;
  border-radius: 50%;
  box-shadow: 0 0 0 1.25px $josef-wolf;

  &.selected {
    background-color: $josef-purple;
    box-shadow: 0 0 0 1.25px $josef-purple;
  }
}
