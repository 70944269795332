@import "../../styles/resources.scss";

.navbar {
  &__group {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex: 0 1 auto;
    padding: $base-spacing * 0.725 $base-spacing;
    height: 100%;

    &--absolute-left {
      position: absolute;
      left: 0;
      width: 280px;

      @include grid-media($grid--desktop-lg-up) {
        width: 390px;
      }
    }

    &--absolute-center {
      position: absolute;
      left: 50%;
      margin: 0 0 0 -255px;
    }

    &--absolute-right {
      position: absolute;
      right: 0;
    }
  }
}
