@use "sass:math";

@import "../../styles/resources.scss";

.navbar {
  background-color: white;
  z-index: map_get($z-index, "navbar");
  display: block;
  top: 0;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  user-select: none;

  &__wrapper {
    position: relative;
    height: 60px;
    // display: flex;
    // height: auto;
    // justify-content: space-between;
    // flex: 1 0 auto;
    // align-items: inherit;
    // justify-content: space-between;
    // flex-direction: row;
    // flex-wrap: wrap;
    border-bottom: 2px solid $josef-purple-soft-dark;

    &.is-light-purple {
      background-color: $josef-purple-pale;
    }
  }

  &__back {
    font-weight: bold;
    display: flex;
    font-size: 1rem;

    .jo-icon {
      width: 0.7em;
      margin-right: 0.5em;
    }
  }

  &__button-label {
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: math.div(14, $rootsize-base) * 1rem;
    font-weight: bold;
    color: $josef-purple;
  }

  &__saving {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    color: $josef-purple;
    margin-left: auto;
    margin-right: 1.25rem;
    margin-bottom: 0;
    min-width: 6rem;
    text-align: right;
    justify-content: flex-end;
    align-items: center;

    .jo-icon {
      margin-right: 0.625rem;
      top: -1px;
    }
  }
}
