// Breakpoints
// best practice breakpoints should be in em units
// function to convert media px statements to em.
// Will only work for media statements due to the way em inherits its values elsewhere in the document
@use "sass:math";

@function media-px-to-em($px) {
  @return math.div(math.div($px, $px * 0 + 1), 16) * 1em;
}

$small-screen: media-px-to-em(600px) !default;
$medium-screen: media-px-to-em(992px) !default;
$large-screen: media-px-to-em(1200px) !default;
$small-screen-up: media-px-to-em(601px) !default;
$medium-screen-up: media-px-to-em(993px) !default;
$large-screen-up: media-px-to-em(1201px) !default;

// Media Queries retained from Materialicss
// $medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
// $large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;  //$grid--desktop-up
// $extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// base font size of html element
$rootsize-base: 12;
$rootsize-lg: 14;

// Grids
$grid--tablet-up: "(min-width: #{$small-screen-up})";
$grid--desktop-up: "(min-width: #{$medium-screen-up})";
$grid--desktop-lg-up: "(min-width: #{$large-screen-up})";

// Typography
$base-font-family: "Chivo", sans-serif; //$font-stack-system;
$heading-font-family: $base-font-family;

$weight-light: 300;
$weight-normal: 400;
$weight-bold: 700;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Used for form label, input and Links
$text-line-height: 1.666rem; //20px;

// Other Sizes
$large-spacing: 2.275rem;
$base-spacing: 1rem; //$base-line-height * 1em;
$small-spacing: $base-spacing * 0.5;
$base-z-index: 0;
$icon-size: $base-spacing * 1.5;

// Radi
$base-border-radius: 4px;
$tour-border-radius: 8px;
$btn-border-radius: 78px;
$switch-radius: 15px !default;

// Border
$base-border-color: $josef-smoke; //$light-gray;
$base-border: 1px solid $base-border-color;
$base-form-border: solid 1px $josef-smoke;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Focus
$focus-outline-color: transparentize($josef-blue, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Animations
$base-duration: 250ms;
$base-timing: ease;

// custom easing
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

// sidebar props
$sidebar-width: px-to-rem(350px);
$sidebar-lg-width: px-to-rem(440px);
$sidebar-duration: 0.375s;
$sidebar-ease: $easeOutCirc;

// navbar props
$navbar-height: px-to-rem(60px);
$navbar-height-lg: px-to-rem(64px, $rootsize-lg * 1px);
$navbar-line-height-mobile: 1rem;

$button-floating-size: 24px !default;
$button-floating-large-size: 44px !default;

$z-index: (
  flow-chart: 50,
  full-modal: 75,
  modal: 300,
  sidebar: 100,
  navbar: 5000,
  popover: 10000,
  modal-backdrop: 10001,
  modal-dialog: 10002,
  // 1100 is the z-index of the sidebar library. we want the sidebar button above it
  sidebar-button: 1101,
);

// shadows for redesign
$shadow-base: 2px 4px 16px rgba(0, 0, 0, 0.1);
$shadow-round: 0px 2px 8px rgba(0, 0, 0, 0.12);
$shadow-zoom: 3px 9px 24px rgba(0, 0, 0, 0.06);
$shadow-grabbed: $shadow-zoom;
$shadow-nav: 0px 4px 11px rgba(0, 0, 0, 0.16);
$shadow-white: 0 3px 10px rgba(255, 255, 255, 0.125);

$shadow-focus: 0 0 0 1px $josef-grey, 0 0 0 3px $josef-iron;
