@import "../../styles/resources.scss";

.popup-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  color: $josef-purple;

  .circle {
    color: $josef-yellow;
    border: 5px solid;
    border-radius: 50%;
    margin-right: 0.3rem;
  }
}

.popup-content {
  border: none !important;
  border-radius: $base-border-radius;
  top: 116px !important;
  background: $josef-white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  border: 1px solid $josef-iron;
  .popup-arrow {
    box-shadow: none !important;
  }
  .close {
    padding: 0;
    right: $base-spacing;
    top: 0.5rem;
    font-size: 1.5rem;
    color: $josef-steel;
    background: none;
    border: none;
    appearance: none;
    outline: none;
  }
  .menu {
    .menu-item {
      padding: 1.5rem;
      .link {
        font-size: $base-spacing;
        text-decoration: underline;
        color: $josef-purple;
        background: none;
        border: none;
        appearance: none;
        outline: none;
      }
    }

    .not-last-item {
      border-bottom: 1px solid $josef-iron;
    }
  }
}
