@import "../../styles/resources.scss";

.debug-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: map_get($z-index, "navbar");
  max-width: 320px;
  padding: $base-spacing * 0.75 $base-spacing;
  top: $navbar-height-lg + 4 * $base-spacing;
  left: $base-spacing;
  border: solid 1px $josef-green;
  background: transparentize($josef-green, 0.6);
  z-index: 9999;
  font-size: 0.9375em;
  line-height: 1.625;

  &.is-pushed-down {
    top: $navbar-height-lg + 4 * $base-spacing + 6.75rem;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &--rule {
      border-bottom: dotted 1px transparentize($josef-oil, 0.7);
      width: 100%;
    }

    &--highlight {
      background-color: $josef-green;
    }
  }

  h4 {
    border-bottom: solid 1px transparentize($josef-oil, 0.75);
    margin: 0 0 0.25rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    flex: 1 1 100%;
    text-transform: uppercase;
    font-size: 0.875em;
  }

  span {
    flex: 1 1 50%;
  }

  strong {
    color: $josef-oil;
    opacity: 0.875;
  }
}
