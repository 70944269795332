.ReactModal__Overlay {
  z-index: map_get($z-index, "modal-backdrop") !important;
}

.ReactModal__Content {
  z-index: map_get($z-index, "modal-dialog") !important;
  padding: 2.5rem 2.5rem !important;
  & h1 {
    font-weight: 700;
  }
}
