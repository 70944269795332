@import "../../styles/resources.scss";

$t-border-default: $josef-silver;
$t-border-hover: $josef-purple-soft-dark;
$t-border-selected: $josef-purple;

.table {
  // width: 100%;
  margin-bottom: 2rem;
  transform: translateZ(0);

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid $t-border-default;
    border-top-width: 0;
    border-bottom-width: 0;
    position: relative;
    box-shadow: 0 -2px 0 $t-border-default, 0 -2px 0 $t-border-default inset;
    // background: orange;

    & + & {
      box-shadow: 0 -2px 0 $t-border-default inset;
    }
  }

  &__cell {
    flex: 1 0 auto;
    padding: 0.5rem 0.5rem;
    margin: -2px 0 0;
    border-left: 1px solid $t-border-default;
    border-right: 1px solid $t-border-default;
    overflow: hidden;
    max-width: 100%;

    &:hover {
      box-shadow: 1px 0 0 $t-border-hover, -1px 0 0 $t-border-hover, 0 -2px 0 $t-border-hover inset,
        0 2px 0 $t-border-hover inset;
      border-color: $t-border-hover;
      color: $josef-purple;
      z-index: 1;
    }

    &.selected-block {
      box-shadow: 1px 0 0 $t-border-selected, -1px 0 0 $t-border-selected, 0 -2px 0 $t-border-selected inset,
        0 2px 0 $t-border-selected inset;
      border-color: $t-border-selected;
      z-index: 2;
    }

    &,
    &:hover,
    &.selected-block {
      &::after {
        content: "";
      }
    }

    &.is-fake {
      cursor: default;

      &:not(.selected-block):not(.textblock):hover {
        border-color: $t-border-default;
      }
    }

    &.unsupported-content {
      display: inline-block;

      &::before {
        top: -9px;
        right: -10px;
      }

      &:hover {
        z-index: 1;
      }

      &:after {
        border-radius: 0;
        margin: 0;
        z-index: -1;
      }

      &::before {
        z-index: 2;
      }
    }

    &.textblock {
      border-color: rgba(0, 156, 233, 0.38);
      background-color: rgba(0, 156, 233, 0.125);
      z-index: 1;

      &:hover {
        box-shadow: 1px 0 0 $josef-sky, -1px 0 0 $josef-sky, 0 -2px 0 $josef-sky inset, 0 2px 0 $josef-sky inset;
        border-color: $josef-sky;
        color: mix($josef-black, $josef-blue, 25%);
      }

      &.selected-block {
        color: mix($josef-black, $josef-blue, 50%);
        box-shadow: 1px 0 0 $josef-sky, -1px 0 0 $josef-sky, 0 -2px 0 $josef-sky inset, 0 2px 0 $josef-sky inset;

        background-color: rgba(0, 156, 233, 0.2);
        border-color: $josef-sky;
      }
    }

    .is-browser-ie & {
      display: inline-flex;
      border: 1px solid $t-border-default;

      * {
        width: 100%;
      }
    }
  }
}

// Set 0 ~ 100% width class for the table cell width.
// Using closest 1 but leaning on flex grow and shrink to cope with any small rounding errors here
@for $i from 1 through 100 {
  $cell-multiple: $i;
  // use flex and width to set our cell size
  .table-cell-width-#{$cell-multiple} {
    flex: $cell-multiple 1 percentage($cell-multiple*0.01);
    width: percentage($cell-multiple*0.01) !important;

    .is-browser-ie & {
      max-width: percentage($cell-multiple*0.01);
      flex: 0 0 percentage($cell-multiple*0.01);
    }
  }
}
