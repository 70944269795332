$levelCount: 10;

body {
  $level: '';
  @for $j from 0 through $levelCount {
    $level: $level + " level" + $j;
  }
  counter-reset: unquote($level);
}

@for $i from 0 through $levelCount {
  // The counter might start at an unexpected number, not zero
  @for $k from 0 through $levelCount {
    .list-restart-level-#{$i}-at-#{$k} {
      // We should start the value of the current level from the specified value.
      $level: 'level' + $i + " " + $k;
      // We let other descendant levels have the default start value of zero.
      @for $j from $i + 1 through $levelCount {
        $level: $level + " level" + $j;
      }
      counter-reset: unquote($level);
    }
  }
}

.list-style-none {
  list-style-type: none !important;
}