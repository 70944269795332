fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: $weight-normal;
  margin-bottom: $small-spacing * 0.5;
  padding: 0;
}

label {
  display: block;
  font-weight: $weight-normal;
  color: $josef-oil;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: 1rem;
  color: $josef-steel;
  border: solid 1px $base-form-border;
  -webkit-font-smoothing: antialiased;
}

$all-text-inputs: "[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea";

#{$all-text-inputs} {
  // note textarea has a wrapper that handles hover/focus
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: 0 1rem; //$base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &::-ms-clear {
    display: none;
  }

  &:hover {
    border-color: $input-border-color-hover;
    color: $base-font-color;
  }

  &:focus {
    border-color: $action-color;
    color: $base-font-color;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;

    label {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  overflow-y: hidden; /* prevents scroll bar flash */
  resize: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing * 0.5;
}

[type="color"] {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}

.input-field {
  font-family: "Chivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;

  &--3qtr {
    flex: 0 1 75%;
    max-width: 75%;
  }

  &--half {
    flex: 0 1 47.5%;
    max-width: 50%;
  }

  &--qtr {
    flex: 0 1 25%;
    max-width: 25%;
  }

  &--disabled {
    pointer-events: none;

    label.input-field__label {
      color: $disabled-color;
    }
  }

  &--invert {
    label.input-field__label {
      color: $josef-white;
    }
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    &:hover + .textarea__wrapper {
      border-color: $action-hover-color;
    }
  }

  label,
  .input-field__label {
    margin-bottom: $small-spacing * 0.5;
    color: $label-color;
    font-weight: $weight-normal;
    font-size: 1rem;
    letter-spacing: -0.02em;
    line-height: 1rem;

    &--invert {
      color: $josef-white;
    }
  }

  &__error {
    color: $danger-color;
    text-align: right;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 0.575rem;
    display: inline-block;
  }

  &.invalid {
    input,
    input[type="text"],
    input[type="url"],
    textarea {
      border: solid 1px $danger-color !important;
      color: $danger-color;
      box-shadow: none;
    }
  }

  // Textarea

  .textarea__wrapper {
    border: $base-form-border;
    border-radius: $base-border-radius;
    padding-top: $base-spacing;
    padding-bottom: $base-spacing;
    padding-right: 1rem;
    overflow: hidden;

    &:hover {
      border-color: $action-hover-color;
    }

    &:focus-within {
      border-color: $action-color;
    }

    @include grid-media($grid--desktop-lg-up) {
      padding-right: 2rem;
    }

    &--error {
      border: solid 1px $danger-color !important;
    }
  }

  textarea {
    // hover states are handled by wrapper ^
    border: none;
    padding: 0 1rem;
    overflow-y: auto;
    margin-bottom: 0;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
  }
}

// react-select custom styling - prefix supplied is react-select
// see https://react-select.com/styles#using-classnames
.react-select {
  &__control {
    cursor: pointer;
    align-items: center;
    background-color: $josef-white;
    border-radius: 4px;
    border: 1px solid $josef-iron;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0;
    position: relative;
    box-sizing: border-box;
    transition: border-color 0.25s;
    color: $josef-steel;

    &:hover {
      color: $josef-black;
      border-color: $josef-purple;
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      & + .react-select__menu {
        &,
        .react-select__option:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  &__menu {
    margin-top: -1px;
    top: 100%;
    background-color: $josef-white;
    border-radius: 4px;
    margin-bottom: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    border: 1px solid $josef-iron;
    border-top: 0 none;
  }

  &__menu-list {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    cursor: pointer;
    background-color: transparent;
    color: $josef-steel;
    display: block;
    font-size: inherit;
    padding: 8px 15px;
    user-select: none;
    box-sizing: border-box;

    &:first-child {
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
    }

    &:active,
    &--is-focused {
      color: $josef-white;
      background-color: $action-hover-color;
    }

    &--is-selected:not(.react-select__option--is-focused) {
      color: $action-color;
    }
  }

  &__value-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 2px 8px 2px 15px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    color: inherit;
  }
}

// React select container
.react-select-container {
  margin-bottom: 0.5rem;

  // hide react select indicator seperator
  [class*="indicatorSeparator"] {
    display: none;
  }

  &--no-search {
    .react-select__value-container {
      display: flex;
      flex: initial;
      flex-wrap: initial;
    }

    .react-select__control {
      min-height: 39px;
    }

    .react-select__single-value {
      width: 100%;
    }
  }

  &.react-select--is-disabled {
    .react-select__control {
      &.react-select__control--is-disabled {
        background: $josef-wolf;
        border-color: $josef-wolf;
      }
    }
  }
}

/* Switch  (based on materializecss)
   ========================================================================== */
.switch {
  &,
  * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  &,
  div,
  label {
    color: inherit;
    display: flex !important;
    align-items: center;
  }

  label {
    margin: 0 0.75rem 0;
    cursor: pointer;
  }

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .rule-groups-container & {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* vue-js-toggle-button
    ========================================================================= */

.v-switch-core {
  background: $josef-blue;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Validations
.validation-error {
  color: color("red", "base");
  font-size: 80%;

  &.auth-error {
    margin-left: 3rem;
  }
}
