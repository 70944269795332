@import "../../styles/resources.scss";

$josef-tooltip-bg-color: $josef-white;
$josef-tooltip-color: black;

.jo-tooltip {
  background-color: $josef-tooltip-bg-color;
  color: $josef-tooltip-color;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  max-width: 350px;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.16) !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  .body {
    text-align: center;

    .attachment {
      text-align: left;
    }
  }
}
