@import "../../styles/resources.scss";

.test-page {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  &__message {
    max-width: 60%;
    text-align: center;
    font-family: monospace;
    margin-bottom: $base-spacing * 2;
  }
}
