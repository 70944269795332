@import "../../styles/resources.scss";

.jo-link {
  font-size: 1em;
  font-weight: bold;
  text-decoration: underline;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: $josef-purple;

  &:hover,
  &:focus {
    color: $josef-dark-purple;
  }

  .jo-icon {
    position: relative;
    top: 0.25rem;
  }

  &.is-red {
    color: $josef-red;

    &:hover,
    &:focus {
      color: $josef-red-dark;
    }
  }

  .is-dark & {
    color: $josef-white;

    &:hover,
    &:focus {
      color: $josef-purple-soft-dark;
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    color: $josef-steel;

    &:hover, 
    &:focus {
      color: $josef-steel;
    }

    .is-dark & {
      color: mix($josef-purple-disabled, $josef-white, 75%);
    }
  }
}
