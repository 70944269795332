$josef-purple: #6339d8;
$josef-dark-purple: #402088;
$josef-purple-disabled: #825add;
// $josef-light-purple: #e9e3f9; //#8f8ff2;
$josef-purple-pale: #e9e3f9;
$josef-purple-soft: #ccbcf8;
$josef-purple-soft-dark: #d3c8f0;
$josef-purple-tint: mix(#fff, $josef-purple, 96%);
$josef-red: #d13e3e;
$josef-red-dark: #b12626;
$josef-light-red: #ffcdd2;
$josef-crimson: darken($josef-red, 8%); // #DC0707;
$josef-green: #00ff9e;
$josef-green-300: #2e966e;
$josef-aqua: #0ae994;
$josef-blue: #009ce9;
$josef-sky: #65ccff;
$josef-sky-dark: #3bb7f4;
$josef-pink: #ff70d7;

$josef-white: #ffffff;
$josef-lt-grey: #fafafa;
$josef-vapor: #f2f2f2; // from design system
$josef-silver: #efefef;
$josef-wolf: #eaeaea;
$josef-smoke: #e4e4e4; // from design system
$josef-iron: #cccccc;
$josef-grey: #9e9e9e;
$josef-steel: #666666; // from design system
$josef-charcoal: #555;
$josef-oil: #282828;
$josef-black: #000000;
$josef-yellow: #f9af6b;
$josef-clear: transparent;
$josef-docusign-yellow: #e1f800;
$base-font-color: $josef-oil;
$disabled-color: $josef-iron;
$action-color: $josef-purple;
$danger-color: $josef-red;
$action-hover-color: $josef-dark-purple;

$dropdown-hover-bg-color: $josef-iron;
$dropdown-bg-color: $josef-white;
$button-disabled-color: $josef-steel;
$placeholder-color: $josef-steel;

// gradient
$josef-gradient: linear-gradient(9.5deg,
      #00ff9e 10.54%,
      #00faa2 13.79%,
      #00c7c8 48.76%,
      #00a8e0 75.23%,
      #009ce9 89.92%);

/* Forms  
   ========================================================================== */
$label-color: $josef-oil;
$form-border-color: $josef-smoke;

$input-border-color: $josef-smoke;
$input-border-color-hover: $josef-purple;
$input-background-color: $josef-white;

$switch-checked-lever-bg: $josef-purple-pale;
$switch-bg-color: $josef-dark-purple;
$switch-unchecked-lever-bg: $josef-iron;
$switch-unchecked-bg: $josef-white;

$collection-border-color: $josef-smoke;

$collapsible-header-color: $josef-white;
$collapsible-border-color: $josef-smoke;

/* Ported across from UI Lib
   ========================================================================== */

// Colours
$josef-neutral-500: #231d32;
/* Dark */
$josef-neutral-400: #5a5466;
/* Standard */
$josef-neutral-300: #c5c3c7;
/* Light */
$josef-neutral-200: #eaeaea;
/* Light pastel */
$josef-neutral-100: #f8f8f9;
/* Lightest */

$josef-terra-400: #87480e;
/* Dark */
$josef-terra-300: #e17e23;
/* Standard */
$josef-terra-200: #f9af6b;
/* Light */
$josef-terra-100: #fff6ed;
/* Lightest */

$josef-red-400: #620909;
/* Dark */
$josef-red-300: #cb3e3f;
/* Standard */
$josef-red-200: #f38485;
/* Light */
$josef-red-100: #ffefef;
/* Lightest */

$pdf-red: #E82425;
$docx-blue: #185ABB;

$inactive-checkbox: $josef-neutral-300; // using existing design system value instead of #a4a4a4