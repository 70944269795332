@import "../../styles/resources.scss";

@mixin hoverable-focus($initial, $focus: $josef-green) {
  &:focus {
    box-shadow: $shadow-focus;
  }

  &,
  &:hover {
    border-color: $initial;
  }
}

.jo-button {
  position: relative;
  appearance: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  font-weight: $weight-bold;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  color: $josef-white;
  background-color: $josef-purple;
  transition-property: opacity, background-color, color;
  transition-duration: $base-duration * 0.5;
  transition-timing-function: $base-timing;
  border: 0 none;
  border-radius: 1.875em;
  line-height: 1;
  font-size: 1rem;
  padding: 1.2em 1.325em 1.1625em;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  @include hoverable-focus(transparent);

  &:focus {
    outline: 0 none;
  }

  &:hover {
    background: $josef-dark-purple;
    color: $josef-white;
  }

  &.is-selected {
    background: $josef-dark-purple;
  }

  // variants
  // with green/blue gradient
  &.is-gradient {
    background-color: $josef-black;
    background-image: $josef-gradient;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 105% 105%;
    background-clip: border-box;
    color: $josef-black;
    overflow: hidden;

    // hover effect with a transition — we can't animate a background gradient so
    &::before {
      content: "";
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      background: black;
      opacity: 0;
      pointer-events: none;
      transition: inherit;
      // transform: translateZ(0);
      border-radius: inherit;
    }

    &:hover {
      &::before {
        opacity: 0.1;
      }
    }
  }

  // blue background
  &.is-blue {
    background-color: $josef-sky;
    color: $josef-black;

    &:focus,
    &:hover {
      background-color: $josef-sky-dark;
    }
  }

  // with border
  &.is-outline {
    background-color: transparent;
    color: $josef-purple;
    @include hoverable-focus($josef-purple);

    &:hover {
      color: $josef-dark-purple;
      background: $josef-purple-soft-dark;
      border-color: $josef-purple-soft-dark;
    }

    .is-dark & {
      color: $josef-white;
      @include hoverable-focus($josef-white);

      &:hover {
        background-color: $josef-dark-purple;
        border-color: $josef-dark-purple;
      }
    }
  }

  // light
  &.is-light {
    background-color: $josef-purple-pale;
    color: $josef-purple;

    &:focus,
    &:hover {
      background-color: $josef-purple-soft-dark;
    }
  }

  // slim button — for nav headers
  &.is-slim {
    padding: 0.75em 0.875em;
  }

  // disabled
  &:disabled {
    &,
    &:hover,
    &:focus,
    &.is-selected {
      cursor: not-allowed;
      background-color: $josef-smoke;
      background-image: none;
      color: $josef-steel;
      box-shadow: none;
      border-image: none;
      border-color: transparent;
    }
    &::before {
      content: none;
    }

    .is-dark & {
      &,
      &:hover {
        color: $josef-dark-purple;
        border-color: $josef-purple-disabled;
        background-color: $josef-purple-disabled;
      }
    }

    .is-light-purple & {
      &,
      &:hover {
        border-color: $josef-vapor;
        background-color: $josef-vapor;
      }
    }
  }

  // reset margins to make buttons flush with siblings and container edges
  &.is-flush,
  &.is-flush-bottom {
    margin-bottom: 0;
  }
  &.is-flush,
  &.is-flush-right {
    margin-right: 0;
  }
}
