@import "../../styles/resources.scss";

.banner {
  padding: 1rem;
  background-color: $josef-purple;
  width: 100%;
  text-align: center;

  &,
  p,
  a {
    color: white;
  }

  p {
    margin: 1rem;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  &.is-warning {
    background-color: $josef-red;
  }
}
