@import "../../styles/resources.scss";

html,
body,
#root,
#App {
  height: 100%;
}
.preloader {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

  &__title {
    color: $josef-purple;
    font-size: 1.25rem;
    font-weight: normal;

    .is-inverted & {
      color: $josef-white;
    }
  }

  &__spinner {
    margin: 25px auto;
    text-align: center;
    transform-origin: 50% 0%;

    &.is-small {
      transform: scale(0.75);
    }
  }

  &__clip {
    animation: preloaderSpin 0.75s 0s infinite linear;
    animation-fill-mode: both;
    display: inline-block;
    height: 60px;
    width: 60px;
    border-width: 2px;
    border-style: solid;
    border-color: $josef-purple $josef-purple transparent;
    border-radius: 100%;
    background: transparent;

    .is-inverted & {
      border-color: $josef-white $josef-white transparent;
    }
  }
}

@keyframes preloaderSpin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
