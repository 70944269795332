@import "../../styles/resources.scss";

.jo-checkbox {
  cursor: pointer;
  display: flex;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &,
  .input-field & {
    color: $josef-purple;
  }

  &:hover,
  &:focus {
    color: $josef-purple;
    outline: 0 none;
  }

  &.is-fullwidth {
    width: 100%;
    justify-content: space-between;

    *:last-child {
      margin-right: 0 !important;
    }
  }

  &__label {
    font-size: 1em;
    margin-right: 1rem;
    color: $josef-oil;

    .jo-checkbox:hover &,
    .jo-checkbox:focus & {
      color: inherit;
    }

    .jo-icon + & {
      margin-right: 0;
    }
  }

  &__input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:focus {
      + span.jo-checkbox__icon > svg {
        outline-offset: 2px;
      }
    }
  }

  &__icon {
    position: relative;
    top: -0.0625rem;
  }

  .is-dark & {
    &,
    &__label {
      color: $josef-white;
    }

    &:hover,
    &:focus {
      color: $josef-purple-soft-dark;
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    color: $inactive-checkbox;

    .is-dark & {
      color: mix($josef-purple-disabled, $josef-white, 75%);
    }

    .jo-checkbox__icon {
      color: $josef-smoke;
    }

    .jo-checkbox__label {
      color: $inactive-checkbox;
    }
  }
}
