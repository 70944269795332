@import "../../styles/resources.scss";

.error-page {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  h1 {
    text-align: center;
    // flex: 1 1 100%;
  }

  &__message {
    color: $josef-red;
    max-width: 60%;
    text-align: center;
    font-family: monospace;
    margin-bottom: $base-spacing * 2;
  }
}
