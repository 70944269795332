@import "../../styles/resources.scss";

.Collapsible {
  position: relative;
  // we need to display a label in between the lines with the operation
  &__operation-label {
    &::before {
      content: attr(data-operation);
      position: absolute;
      top: 0;
      margin-top: -0.925em;
      padding: 0 0.5em;
      background-color: $josef-white;
      color: $josef-grey;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // handle border-radius combinations
  // first child should have rounded tops
  &:first-child {
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;

    .Collapsible__operation-label::before {
      content: none;
    }
  }

  // last child should have rounded bottoms
  &:last-child {
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
  }

  &__trigger {
    padding: $base-spacing;
    cursor: pointer;
    border-bottom: 1px solid $josef-smoke;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    background: $josef-white;
    color: $josef-black;
    border-radius: inherit;

    &:hover {
      color: $josef-purple;
    }

    &::after {
      // TEMP ARROW STYLE .. just to make it clear what this is in the UI
      // Should be replaced by proper icon later
      content: "";
      border-right: 2px solid $josef-steel;
      border-bottom: 2px solid $josef-steel;
      display: block;
      height: 0.575em;
      width: 0.575em;
      transform: rotateZ(45deg);
      float: right;
      margin-top: 0.5em;
    }

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transform: rotateZ(225deg);
      }
    }
  }

  &__trigger-element {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;

    &--missing {
      color: $josef-red;
    }

    .jo-link {
      color: $josef-steel;
      margin: 0;
      margin-left: $small-spacing;

      &:hover:not(.is-disabled) {
        color: $josef-purple;
      }
    }
  }

  &__contentInner {
    padding: $base-spacing $base-spacing 0;
    border-bottom: 1px solid $josef-smoke;
    border-radius: inherit;
  }

  &__contentOuter {
    background-color: $josef-white;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
}

.rule-answer {
  $self: &;

  display: flex;
  position: relative;

  &:active,
  &:focus {
    outline: unset;
    cursor: text;
  }

  &__display {
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;

    #{$self}--focused & {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__input {
    height: 3rem;

    &--formatted {
      color: $josef-clear;
      -webkit-appearance: none;

      &:not(:focus):hover {
        color: $josef-clear !important;
      }
    }

    &:disabled {
      background-color: #eaeaea;
      border-color: #eaeaea;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      height: 3rem;
    }
  }

  &__duration-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__duration-unit-select {
    flex: 1 0 150px;
    margin-left: $base-spacing;
  }
}

.input-field {
  margin-bottom: 1.5rem;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;

  &__label {
    margin-bottom: 0.25rem;
    color: $josef-oil;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: -0.02em;
    line-height: 1rem;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem;
  }
}
