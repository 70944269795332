@import "../../styles/resources.scss";

.toolbar {
  background: $josef-purple-pale;
  width: 100%;
  flex: 1 0 100%;

  &__wrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
}

.caps {
  padding-top: -25px;
  position: absolute;
  top: -5px;
  left: -6px;
}
