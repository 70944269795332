@import "../../styles/resources.scss";

$hover-margin: 3px;

.jo-toolbar-button {
  $this: &;
  appearance: none;
  user-select: none;
  cursor: pointer;
  border-radius: 0;
  color: $josef-purple; //$josef-oil;
  padding: 0.7rem 0.825rem;
  background: inherit;
  transition: none;
  border: 0 none;
  position: relative;
  font-size: 1em;

  &::before {
    content: "";
    display: block;
    position: absolute;
    right: $hover-margin;
    bottom: $hover-margin;
    left: $hover-margin;
    top: $hover-margin;
    border-radius: 3px;
  }

  .is-vertical & {
    border-top: 1px solid $josef-purple-soft-dark;
  }

  .is-vertical &:first-child {
    border-top: 0 none;
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
  }

  .is-vertical &:last-child {
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
  }

  .is-horizontal & {
    border-right: 1px solid $josef-purple-soft-dark;
    padding: 0.875rem 1rem;
  }

  &.is-active {
    color: $josef-white;

    &::before {
      background: $josef-purple;
    }
  }

  &:hover {
    color: $josef-white;

    &::before {
      background: $josef-dark-purple;
    }
  }

  &:disabled {
    color: $josef-steel;
    cursor: not-allowed;

    &::before {
      background: $josef-smoke;
    }
  }
}
