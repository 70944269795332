@import "../../styles/resources.scss";

.ReactModal__Content {
  .body {
    line-height: 1.3rem;
  }
  .btn-group {
    padding-top: 2.5rem;
  }
}
