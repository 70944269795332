@import "../../styles/resources.scss";

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $base-spacing;

  &:hover {
    color: $josef-purple;
  }

  // text for the operation
  &::before {
    content: attr(data-operation);
    position: absolute;
    top: 0;
    margin-top: -0.8em;
    padding: 0 0.5em;
    background-color: $josef-white;
    color: $josef-grey;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }

  // first child doesn't need operation label
  &:first-child {
    &::before {
      content: none;
    }
  }

  &__icon-span {
    display: flex;
    align-items: center;
    color: $josef-steel;
  }

  // right arrow
  &__arrow {
    display: flex;
    align-items: center;

    .jo-icon {
      width: 0.8em;
      margin-left: 0.5em;
    }
  }

  // delete (bin) icon
  &__delete {
    .jo-link {
      margin: 0;
      color: $josef-steel;

      &:hover {
        color: $josef-purple;
      }
    }
  }

  &:first-child {
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
  }
}

.rule-groups-container {
  width: 100%; //200%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: transform $base-duration $base-timing;

  &--slide-rulegroup {
    transform: translateX(calc(-100% - #{$base-spacing * 1}));

    -ms-transform: translateX(-100%) translateX(#{$base-spacing * -1});
  }
}

.rule-group-overflow-control-wrapper {
  overflow-x: hidden;
}

.b-rule-group {
  width: 100%;
  flex: 0 0 100%;

  &__no-rules {
    margin-top: $base-spacing * 2;
  }

  &__wrapper {
    border-left: 1px solid $josef-smoke;
    border-radius: $base-border-radius;
    border-right: 1px solid $josef-smoke;
    border-top: 1px solid $josef-smoke;
  }
}

.rule-actions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $base-spacing * 1.5;
}

.rule-actions-add {
  display: flex;
}

.rule-group__column {
  width: 100%;
  flex: 0 0 100%;
  margin-left: $base-spacing;
}