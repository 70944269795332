@use "sass:math";

@import "../../styles/resources.scss";
@import "./css/list.scss";

$block-vert-spacing: math.div($base-spacing, 3);
$block-horz-spacing: $base-spacing;

$document-margin: 2.5rem;

$looping-border-hover: mix($josef-pink, #fff, 75%);
$looping-border: $josef-pink;

.docusign_recipients_button {
  align-items: center;
  background-color: $josef-white;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid $josef-vapor;
  display: flex;
  justify-content: center;
  pointer-events: auto;
  position: relative;
  user-select: none;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 8px;
    text-decoration: none;
    margin-right: 0;
    margin-bottom: 0;
  }

  &__label {
    font-size: px-to-rem(15px);
    display: block;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    white-space: nowrap;
  }

  &__border {
    background-color: $josef-green;
    display: block;
    height: 2px;
    width: 24px;
    margin-top: 9px;
    margin-bottom: 8px;
  }
}

.sidebar__tab {
  position: fixed;
  right: 0;
  z-index: map_get($z-index, "sidebar-button");
  width: 42px;
  margin-right: $sidebar-width !important;

  @include grid-media($grid--desktop-lg-up) {
    margin-right: $sidebar-lg-width !important;
  }

  top: 150px;

  &__hidden {
    display: none;
  }
}

.main {
  padding: $document-margin $document-margin $document-margin + 1rem;
  background: $josef-smoke;
  overflow: auto;
  scroll-behavior: smooth;
}

.editor-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  // this element manages the centering positioning of our document when the sidebar is open
  // in short, it animates from a zero width to the size of the sidebar, and the document centers in the space left over
  &::after {
    content: "";
    display: block;
    flex: 1 0 $sidebar-width;
    will-change: width, max-width;
    transition: max-width $sidebar-duration $sidebar-ease;
    max-width: 0px;

    @include grid-media($grid--desktop-lg-up) {
      flex: 1 0 $sidebar-lg-width;
    }

    // to visualise what's going on here, enable these lines:
    // background: red;
    // height: 100px;
  }

  // here's the magic to make the content area shift over while still centered!
  &.has-sidebar-open {
    &::after {
      max-width: $sidebar-width;

      @include grid-media($grid--desktop-lg-up) {
        max-width: $sidebar-lg-width;
      }
    }
  }
}

// Styling text rules
// mixin to keep consitentcy between TEXTRULE and TEXTRULE_ACTIVE
@mixin textrule($color, $bgcolor, $border: mix($color, $bgcolor, 25%), $showBorder: false) {
  color: $color;
  background-color: $bgcolor;
  max-width: none;
  position: relative;
  padding-left: 1px;
  padding-right: 0;

  // ensure parent font-family is respected
  font-family: inherit !important;

  > span {
    // here we compensate for the extra 1px padding
    z-index: 1;
    position: relative;
  }

  // touching text rule style ranges
  & + & {
    margin-left: 0;
    padding-left: 0;
  }

  // Border doesn't work properly currently because we can't differentiate between different textrules
  // When we fix that (eg: by applying styles via TEXTRULE_{id} instead, we can re-enable these styles
  @if $showBorder ==true {
    @if alpha($bgcolor) !=1 {
      $alpha: alpha($bgcolor) * 100%;
      background-color: mix(opacify($bgcolor, 1), #ffffff, $alpha);
    }

    border: 1px solid $border;
    border-radius: 3px;
    padding-right: 1px;

    & + & {
      margin-left: -2px;
      border-left: 0 none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right: 1px solid $border;
    }
  }
}

// Our text rules receive a font-family with their name in it
// This means we can style them here in scss
// TEXTRULE
[style*="font-family: TEXTRULE"] {
  @include textrule(#0075af, rgba(0, 156, 233, 0.1), #99c2da);
}

// TEXTRULE_ACTIVE
[style*="font-family: TEXTRULE_ACTIVE"] {
  @include textrule(#004875, rgba(0, 156, 233, 0.2), #add5ea);
}

.answer {
  cursor: pointer;
  @include textrule($josef-white, $josef-purple, rgba(0, 0, 0, 0.5));
  margin-left: 1px;
  margin-right: 1px;

  span {
    background-color: transparent !important;
    border: 0 none !important;
  }

  &::before {
    content: attr(title);
    margin-right: -0.25em;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  .jo-icon {
    width: 0.7em !important;
    margin-left: $base-spacing * 0.25;

    &:hover {
      color: $josef-dark-purple;
    }

    &:active {
      color: $josef-white;
    }
  }
}

.docusign-tab-answer-wrapper {
  cursor: pointer;
  display: inline-flex;
  background: $josef-docusign-yellow;
  align-items: center;
  vertical-align: middle;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 2px;
  line-height: $base-spacing;

  .docusign-tab-answer {
    span {
      background-color: transparent !important;
      border: 0 none !important;
    }

    &::before {
      content: attr(title);
      margin-right: -0.25em;
      text-decoration: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-family: inherit;
    }
  }
}

.docusign-tab-not-found-wrapper {
  cursor: pointer;
  display: inline-flex;
  background: $josef-red;
  align-items: center;
  vertical-align: middle;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 2px;
  line-height: $base-spacing;

  .docusign-tab-removed {
    color: $josef-white;

    span {
      background-color: transparent !important;
      border: 0 none !important;
    }

    &::before {
      content: attr(title);
      margin-right: -0.25em;
      text-decoration: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-family: inherit;
    }
  }

  .jo-icon {
    width: 0.7em !important;
    margin-left: $base-spacing * 0.25;
    margin-right: $base-spacing * 0.25;
    color: $josef-white;

    &:hover {
      color: $josef-dark-purple;
    }

    &:active {
      color: $josef-white;
    }
  }
}

.tab {
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;

  span {
    background-color: transparent !important;
    border: 0 none !important;
  }

  &::before {
    display: inline-block;
    content: attr(title);
    margin-right: -0.25em;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
}

.xref {
  cursor: pointer;
  @include textrule($josef-white, $josef-green-300, rgba(0, 0, 0, 0.5));
  margin-left: 1px;
  margin-right: 1px;

  span {
    background-color: transparent !important;
    border: 0 none !important;
  }

  &::before {
    content: attr(title);
    margin-right: -0.25em;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
}

// [data-block] is any editable block in draftjs
// eg: h1, h2, h3, h4, li, p
// these need some styles to create their padding, borders, backgrounds for block rules, etc
[data-block] {
  line-height: 1.875;
  margin-bottom: $base-spacing;
  position: relative;
  word-break: break-word;

  &.align-left {
    .public-DraftStyleDefault-block {
      text-align: left;
    }
  }

  &.align-center {
    .public-DraftStyleDefault-block {
      text-align: center;
    }
  }

  &.align-right {
    .public-DraftStyleDefault-block {
      text-align: right;
    }
  }

  &.align-justify {
    .public-DraftStyleDefault-block {
      text-align: justify;
    }
  }

  // the text editor selection / interaction is handled by a pseudo element
  // this is because we can position it and pad it without affecting layout of the editor elements
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
    border: solid 2px $josef-purple-soft-dark;
    border-radius: $base-border-radius * 1.75;
    padding: $block-vert-spacing $block-horz-spacing;
    margin: $block-vert-spacing * -1 $block-horz-spacing * -1;
    opacity: 0;
    will-change: opacity, border-color, background-color;
  }

  // unsupported content
  &.unsupported-content {
    color: $josef-charcoal;
    overflow: visible;

    &::before {
      content: "";
      border: 0.7rem solid $josef-yellow;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      right: -19px;
      top: -10px;
      height: 1rem;
      width: 1rem;
    }

    &,
    &:hover,
    &.selected-block {
      &::after {
        opacity: 0.5;
        z-index: 1;
        background-color: $josef-iron;
      }
    }

    &:hover {
      &::after {
        border-color: $josef-steel;
      }
    }

    &.selected-block {
      &::after {
        border-color: black;
      }
    }

    &,
    *,
    &::before,
    &::after {
      cursor: pointer;
    }

    user-select: all;
  }

  // basic hover state — show border
  &:hover {
    &::after {
      opacity: 1;
      border-color: $josef-purple-soft-dark;
    }
  }

  // basic select state
  &.selected-block {
    &::after {
      opacity: 1;
      border-color: $josef-purple;
    }
  }

  // when a text rule is applied, it goes blue
  &.textblock {
    // default textrule state
    color: mix($josef-black, $josef-blue, 25%);

    &::after {
      opacity: 1;
      background-color: rgba(0, 156, 233, 0.125);
      border-color: transparent;
    }

    // on hovered textrule we just change the border colour
    &:hover {
      &::after {
        border-color: $josef-sky;
      }
    }

    // a selected textrule has a few colour changes throughout
    &.selected-block {
      color: mix($josef-black, $josef-blue, 50%);

      &::after {
        background-color: rgba(0, 156, 233, 0.2);
        color: mix($josef-black, $josef-blue);
        border-color: $josef-sky;
      }
    }
  }

  &.is-looping-block-table {
    & {
      opacity: 1;
    }

    & {
      box-shadow: 1px 0 0 $looping-border, -1px 0 0 $looping-border, 0 -2px 0 $looping-border inset,
        0 2px 0 $looping-border inset;
      border-color: $looping-border;
    }

    &:hover,
    &.selected-block {
      box-shadow: 1px 0 0 $looping-border-hover, -1px 0 0 $looping-border-hover, 0 -2px 0 $looping-border-hover inset,
        0 2px 0 $looping-border-hover inset;
      border-color: $looping-border-hover;
    }
  }

  &.is-looping-block {
    &::after {
      opacity: 1;
    }

    &,
    &:hover,
    &.selected-block {
      &::after {
        border: 2px solid $looping-border-hover;
      }
    }

    &:hover {
      &::after {
        border-color: $josef-pink;
      }
    }
  }

  &.hidden {
    display: none;
  }

  &.deleted {
    opacity: 0.3;
  }

  &.duplicated {
    border: 2px dashed red;
  }
}

// give headings extra space above
.editor-container {
  line-height: 1.25;
  position: relative;
  margin: 0 0 $base-spacing * 2;
  padding: $base-spacing * 4.25 $base-spacing * 4.5;
  box-shadow: $shadow-base;
  max-width: 56rem;
  background: $josef-white;
  min-height: calc(90vh);
  flex: 0 1 56rem;
  will-change: width, max-width;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $weight-bold;
    line-height: inherit;
  }

  // Taken from Modular Scale - Custom 1.275
  // https://type-scale.com/?size=14&scale=1.275&text=Josef%20typographic%20scale&font=Chivo&fontweight=600

  // reduce the scale very slightly at each point to maintain overall hierarchical contrast,
  // while keeping the lesser used h5 / h6 quite small so the jump up to more commonly used h1-h4 is smaller
  $scale-reducer: 0.1rem;

  h1 {
    font-size: 4.296rem - $scale-reducer;
  }

  h2 {
    font-size: 3.369rem - $scale-reducer;
  }

  h3 {
    font-size: 2.643rem - $scale-reducer;
  }

  h4 {
    font-size: 2.073rem - $scale-reducer;
  }

  h5 {
    font-size: 1.626rem - $scale-reducer;
  }

  h6 {
    font-size: 1.275rem - $scale-reducer;
  }
}

.editor-footer-buttons {
  position: fixed;
  bottom: 1.25rem;
  right: 2rem;
}

.jo-tooltip {
  border-radius: $tour-border-radius !important;
  font-size: 1rem;
  line-height: $base-line-height;
  font-weight: $weight-normal;
}

.format-switch {
  color: $josef-purple !important;
  padding-right: 15px;
}

.bold {
  font-weight: bold;
}

.disabled {
  color: $disabled-color;
}

// in IE, tables don't have a wrapper so we need to style the content areadifferently to support rows
.is-browser-ie .public-DraftEditor-content {
  > [data-contents="true"] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  [data-block] {
    width: 100%;
  }
}

.painter-selected {
  cursor: url("../../assets/color-brush-1.svg"), cell;

  .answer,
  .tab,
  .xref,
  .unsupported-content {
    &,
    *,
    &::before,
    &::after {
      cursor: url("../../assets/color-brush-1.svg"), cell;
    }
  }
}

.view-only-wrapper {
  margin-left: $base-spacing * 2.75;
}

.no-recipient-wrapper {
  margin-top: 24px;
}

.no-recipient-div {
  margin: 0.25em 0.125em;
}

.document-name-wrapper {
  width: 100%;
}

.document-name-input.document-name-input {
  border: solid 1px transparent;
  padding: 0.25rem $small-spacing;
  margin-left: $small-spacing;
  margin-bottom: 0;
  font-size: 18px;

  &:focus {
    border: solid 1px $josef-purple;
  }
}

.link {
  cursor: pointer;
  @include textrule($josef-white, $josef-pink, rgba(0, 0, 0, 0.5));
  margin-left: 1px;
  margin-right: 1px;

  span {
    background-color: transparent !important;
    border: 0 none !important;
  }

  &::before {
    content: attr(title);
    margin-right: -0.25em;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
}

.settings-sidebar {
  h3,
  p {
    margin-bottom: $base-spacing;
  }

  &__section {
    padding-bottom: $small-spacing;
  }

  &__toggle-wrapper {
    display: flex;
    gap: 8px;
    margin-bottom: $base-spacing * 0.75;
  }

  &__section-heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &____doc-export-wrapper {
    display: flex;
  }
}

.document-card {
  width: 100%;
  background: $josef-neutral-100;
  padding: $base-spacing;
  border-radius: $tour-border-radius;
  display: flex;
  justify-content: space-between;
  margin-bottom: $base-spacing * 1.2;

  &__content {
    h4 {
      font-weight: $weight-normal;
    }

    display: flex;
    align-items: center;
  }

  &__docx-icon {
    color: $docx-blue;
  }

  &__pdf-icon {
    color: $pdf-red;
  }

  &__heading {
    color: $josef-neutral-500;
    margin-left: $base-spacing;
    max-width: 285px;
    line-height: 150%;
  }

  &__info {
    color: $josef-neutral-400;
  }

  &__action {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
}
