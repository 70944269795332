.jo-icon {
  display: inline-block;
  position: relative;
  width: 1.125em;
  color: inherit;
  margin: 0;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    width: 100%;
  }

  &.has-spacing-right {
    margin-right: 0.75rem;

    .jo-button.is-slim & {
      margin-right: 0.625rem;
    }
  }

  &.has-spacing-left {
    margin-left: 0.75rem;

    .jo-button.is-slim & {
      margin-left: 0.625rem;
    }
  }

  &.is-large {
    width: 2rem;
    height: 2rem;
  }

  &__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  svg,
  path {
    fill: inherit; // legacy fallback, will need to declare fill on parent to work
    fill: currentColor;
  }

  // common style for floating buttons
  .floating-tour-btn & {
    width: 1.625rem;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}