@import "../../styles/resources.scss";

.cta-message-bar {
  position: relative;
  align-items: center;
  display: flex;
  border-radius: $base-border-radius;
  padding: 0.75em 0.875em;
  // font-size: $text-tiny;
  line-height: 150%;
  max-height: 38px;

  span {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: flex;
    align-items: center;
  }

  &.no-max-height {
    max-height: none;
  }

  &.is-primary {
    border: solid 1px $josef-purple;
    background: $josef-purple-pale;
    color: $josef-dark-purple;
  }

  &.is-info {
    border: solid 1px $josef-neutral-400;
    background: $josef-neutral-100;
    color: $josef-neutral-500;
  }

  &.is-warning {
    border: solid 1px $josef-terra-300;
    background: $josef-terra-100;
    color: $josef-terra-400;
  }

  &.is-danger {
    border: solid 1px $josef-red-300;
    background: $josef-red-100;
    color: $josef-red-400;
  }
}
