@use "sass:math";

:root,
html {
  font-family: $base-font-family;
  // 12px is our base font-size
  font-size: $rootsize-base * 1px;
  font-weight: $weight-normal;
  font-display: swap;

  @include grid-media($grid--desktop-lg-up) {
    // 14px is our base font-size
    font-size: $rootsize-lg * 1px;
  }
}

html {
  color: $base-font-color;
  line-height: $text-line-height; //$base-line-height;
}

body {
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

h1 {
  // for Page Heading
  font-size: math.div(22, $rootsize-base) * 1rem; // 22px
  line-height: 1;
  margin: 0 0;
  font-weight: $weight-normal; //$weight-bold;
  margin-bottom: $base-spacing * 2;
}

h2 {
  font-size: math.div(14, $rootsize-base) * 1rem; // 14px
  line-height: math.div(20, 14); // 20px
  margin: 0 0; //$small-spacing
}

h3 {
  font-size: 1rem;
  line-height: 1.66;
  margin: 0 0;
}

h4,
h5,
h6 {
  font-size: 1rem;
  line-height: 1.666;
  margin: 0 0;
}

p {
  font-size: 1rem;
  line-height: 1.666;
  margin: 0 0 $small-spacing;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
}

dt {
  font-weight: 600;
}

dl,
dt,
dd,
ul,
ol {
  margin: 0;
}

a {
  color: $josef-purple;
  text-decoration-skip-ink: auto;
  transition: color $base-duration $base-timing;
  text-decoration: none;

  // Gets rid of tap active state — should provide alternative focus style?
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: $josef-dark-purple;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    outline-offset: $focus-outline-offset;
  }

  .is-dark & {
    color: $josef-white;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: $josef-smoke;
}

// font weight helper class
.medium-heading {
  font-weight: $weight-normal;
}

// Depreciate: shouldn't be needed if we set up containers to set color
// and children to use `color: inherit`
.text-white {
  color: $josef-white;
}

// Space below our headings
.space-below {
  margin-bottom: 1.575rem;
}

.small-space-below {
  margin-bottom: $base-spacing;
}

// Basic text Styles
.small-text {
  font-size: math.div(10, $rootsize-base) * 1rem; // 12px;
}