@import "../../styles/resources.scss";

.docusign-recipient-answer {
  &__input {
    height: 3rem;
    margin-bottom: 0px !important;
  }

  &__dropdown {
    margin-bottom: 0px !important;
  }
}

.docusign-contact-loader-wrapper {
  display: flex;
  align-items: center;
}

.docusign-contact-loader {
  animation: preloaderSpin 0.75s 0s infinite linear;
  animation-fill-mode: both;
  margin-top: 2px;
  height: 15px;
  width: 15px;
  border-width: 2px;
  border-style: solid;
  border-color: $josef-charcoal $josef-charcoal transparent;
  border-radius: 100%;
  background: transparent;
  float: left;
}
